import React from "react";
import Home from "./pages/Home";

const App = () => {
  React.useEffect(() => {
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/kumu.matomo.cloud/container_Op8aUT64.js'; s.parentNode.insertBefore(g,s);
   }, [])
   console.log("missael matomo")
  return (
    <>
      <Home />
    </>
  )
}
export default App;